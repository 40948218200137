import React, { useRef, useEffect, useState } from 'react';
import downloadApple from '../media/app-store-badge-nl.png';
import downloadGoogle from '../media/google-play-badge-nl.png';
import '../App.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const sleep = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const HomePage = () => {

  const video1Ref = useRef<HTMLVideoElement>(null);
  const video2Ref = useRef<HTMLVideoElement>(null);
  const [isPlaying1, setPlaying1] = useState<boolean>(false);
  const [isPlaying2, setPlaying2] = useState<boolean>(false);

  // useEffect(() => {
  //   async function playVideo() {
  //     await sleep(2000);
  //     if (videoRef.current) {
  //       videoRef.current.play();
  //     } else {
  //       console.log('videoRef undefined');
  //     }
  //   }
  //   playVideo();
    
  // }, []);

  return (
    <div className="App">

      <div className="Header mb-3">
        <h2 className="mt-2 mb-4">
          BuzzerBee - weermeldingen
        </h2>
        <p className="header-text">
          BuzzerBee 🐝 stuurt je gratis gepersonaliseerde weermeldingen. Je krijgt een notificatie wanneer de voorspelde weersomstandigheden ideaal zijn voor jouw activiteiten, zoals barbecueën ♨️, surfen 🏄‍♀️, fietsen 🚴‍♂️ of parachutespringen 🪂. Daarnaast kan de app je ook waarschuwen bij toekomstige uitzonderlijke weersomstandigheden, zoals vorst ❄️ of storm 🌪️, zodat je tijdig voorzorgsmaatregelen kunt nemen.
        </p>
        <Container className='download-button-container justify-content-center'>
          <Row className="align-items-center">
            <Col md={6} style={{textAlign: 'center'}}>
              <a href="https://apps.apple.com/nl/app/buzzerbee/id1539478065" target={'_blank'}>
                <img src={downloadApple} className="download-button" alt="logo" />  
              </a>
            </Col>
            <Col md={6} style={{textAlign: 'center'}}>
              <a href="https://play.google.com/store/apps/details?id=nl.buzzerbee.app&gl=NL" target={'_blank'}>
                <img src={downloadGoogle} className="download-button" alt="logo" />
              </a>
            </Col>
          </Row>
        </Container>
      </div>

    </div>
  );
}
export default HomePage;
