import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavBar from './components/navbar/NavBar';
import HomePage from './pages/HomePage';
import WhatsNewPage from './pages/WhatsNewPage';
import GetTheAppPage from './pages/GetTheApp';
import OutsideTapPage from './pages/OutsideTapPage';
// import WebFont from 'webfontloader';

function App() {

  // useEffect(() => {
  //   WebFont.load({
  //     google: {
  //       families: ['Material Icons']
  //     }
  //   });
  //  }, []);

  return (
    <BrowserRouter>
      <NavBar />
      <Routes>
        <Route path='/' element={<HomePage/>} />
        <Route path='/whatsnew' element={<WhatsNewPage/>} />
        <Route path='/gettheapp' element={<GetTheAppPage/>} />
        <Route path='/buitenkraan-afsluiten-bij-vorst' element={<OutsideTapPage/>} />
        {/* <Route path="*" element={<NotFound/>}/> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
