import React, { useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import infinityVideo from '../media/infinity.mp4';
import sortAlarmsVideo from '../media/sort-alarms.mp4';

const WhatsNewPage = () => {

  const video1Ref = useRef<HTMLVideoElement>(null);
  const video2Ref = useRef<HTMLVideoElement>(null);
  const [isPlaying1, setPlaying1] = useState<boolean>(false);
  const [isPlaying2, setPlaying2] = useState<boolean>(false);

  // useEffect(() => {
  //   async function playVideo() {
  //     await sleep(2000);
  //     if (videoRef.current) {
  //       videoRef.current.play();
  //     } else {
  //       console.log('videoRef undefined');
  //     }
  //   }
  //   playVideo();
    
  // }, []);

  return (
    <div className="App">
      
      <div className="Header mb-3">
        <Container>
          <Row>
            <Col lg={6}>
              <h2 className="mt-2 mb-4">
                Oneindige mogelijkheden
              </h2>
              <p className="font-italic" style={{fontStyle: 'italic', marginTop: -20}}>
                {/* 28-03-2022 */}
                Versie 0.3.1
              </p>
              <p>
                In versie 0.3.1 van de app kun je het bereik van bijvoorbeeld temperatuur en neerslag tot <b>oneindig</b> instellen.
                Dit biedt je nóg meer mogelijkheden om je melding op jouw wensen af te stemmen.
                Enkele voorbeelden in de app maken hier nu ook gebruik van.
              </p>
              <p>
                Daarnaast zijn er onder de motorkap verschillende wijzigingen gedaan om de gebruikerservaring
                te verbeteren.
              </p>
            </Col>
            <Col lg={6} style={{textAlign: 'center'}}>
              <video ref={video1Ref} src={infinityVideo} className="video" onClick={() => {
                if (video1Ref.current) {
                  if (!isPlaying1) {
                    video1Ref.current.play();
                    setPlaying1(true);
                  } else {
                    video1Ref.current.pause();
                    setPlaying1(false); 
                  }
                }
              }} playsInline autoPlay loop/>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="Header mb-3">
        <Container>
          <Row>
            <Col lg={6}>
              <h2 className="mt-2 mb-4">
                Meldingen ordenen en delen
              </h2>
              <p className="font-italic" style={{fontStyle: 'italic', marginTop: -20}}>
                {/* 09-12-2021 */}
                Versie 0.3.0
              </p>
              <p>
                Nieuw in versie 0.3.0 is de mogelijkheid je meldingen te <b>ordenen</b>. 
                Pak een melding vast en versleep het naar boven of onder.
              </p>
              <p>
                Swipe je een melding naar links, dan verschijnen twee opties in het snelmenu.
                Hier kun je kiezen voor <b>delen</b> om jouw eigen melding met anderen te delen.
                Ook kun je eenvoudig een melding uit je lijst <b>verwijderen</b>.
              </p>
            </Col>
            <Col lg={6} style={{textAlign: 'center'}}>
            <video ref={video2Ref} src={sortAlarmsVideo} className="video" onClick={() => {
                if (video2Ref.current) {
                  if (!isPlaying2) {
                    video2Ref.current.play();
                    setPlaying2(true);
                  } else {
                    video2Ref.current.pause();
                    setPlaying2(false); 
                  }
                }
              }} playsInline autoPlay loop/>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="Header mb-3">
        <Container>
          <Row>
            <Col>
            {/* <Col lg={6}> */}
              <h2 className="mt-2 mb-4">
                Badges
              </h2>
              <p className="font-italic" style={{fontStyle: 'italic', marginTop: -20}}>
                {/* 25-02-2020 */}
                Versie 0.2.2
              </p>
              <p>
                Vanaf versie 0.2.2 hoef je geen notificaties meer te missen.
                Het aantal ongeopende notificaties verschijnt in het rode bolletje boven
                het BuzzerBee app-icoon en in de Inbox-tab in de app.
              </p>
            </Col>
            {/* <Col lg={6} style={{textAlign: 'center'}}>
            <video ref={video2Ref} src={sortAlarmsVideo} className="video" onClick={() => {
                if (video2Ref.current) {
                  if (!isPlaying2) {
                    video2Ref.current.play();
                    setPlaying2(true);
                  } else {
                    video2Ref.current.pause();
                    setPlaying2(false); 
                  }
                }
              }} playsInline autoPlay loop/>
            </Col> */}
          </Row>
        </Container>
      </div>

      <div className="Header mb-3">
        <Container>
          <Row>
            <Col>
            {/* <Col lg={6}> */}
              <h2 className="mt-2 mb-4">
                Meldingen delen
              </h2>
              <p className="font-italic" style={{fontStyle: 'italic', marginTop: -20}}>
                {/* 15-02-2020 */}
                Versie 0.2.1
              </p>
              <p>
                Met versie 0.2.1 van de app kun je jouw meldingen nu ook delen met vrienden.
                Druk in Mijn Meldingen op het icoontje om een melding te delen.
                Je kunt nu een linkje delen die de ontvanger direct kan openen met de BuzzerBee app.
                De melding wordt zo direct aan zijn of haar lijst toegevoegd.
                Handig voor een gezamenlijke activiteit!
              </p>
            </Col>
            {/* <Col lg={6} style={{textAlign: 'center'}}>
            <video ref={video2Ref} src={sortAlarmsVideo} className="video" onClick={() => {
                if (video2Ref.current) {
                  if (!isPlaying2) {
                    video2Ref.current.play();
                    setPlaying2(true);
                  } else {
                    video2Ref.current.pause();
                    setPlaying2(false); 
                  }
                }
              }} playsInline autoPlay loop/>
            </Col> */}
          </Row>
        </Container>
      </div>

    </div>
  );

}
export default WhatsNewPage;
