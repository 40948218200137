import React, { useRef, useState } from 'react';
import { Col, Container, Row, Stack } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { mdiBellPlusOutline } from '@mdi/js';
import Icon from '@mdi/react';

const OutsideTapPage = () => {

  const navigate = useNavigate();

  return (
    <div className="App">

      <div className="Header mb-3">
        <Container>
          <Row>
            <Col>
              <h1 className="mt-2 mb-4">Buitenkraan afsluiten bij vorst</h1>

              <p>
                Een melding op je telefoon ontvangen wanneer je de buitenkraan moet afsluiten?
                Installeer <Link to={'/'}>Buzzerbee app</Link> en voeg onderstaande melding toe aan je alarmen:
              </p>

              <Stack direction="horizontal" className="AlarmContainer mb-4" onClick={() => {
                // window.location.replace('https://buzzerbee.nl/details/djI7QnVpdGVua3JhYW4gYWZzbHVpdGVuOyVGMCU5RiU5QSVCMDswOzswOzA7MTs7MDsxMjsxNTsxOzE4OjAwOzA7LTEwOzQwOzE7LTEwOzA7MDsxOzA7MDsyMDswOzA7MTI7MDswOzA7MDsxOzIwOzQw');
              }}>
                <div className="AlarmColumn" style={{fontSize: 35, marginLeft: -15, marginRight: -10}}>🚰</div>
                <div className="AlarmColumn" style={{textAlign: 'left'}}>Buitenkraan afsluiten</div>
                {/* <div className="AlarmColumn">
                  <Icon path={mdiBellPlusOutline}
                    size={1.5}
                    color="black"/>
                </div> */}
                {/* <Col className="AlarmColumn" style={{ fontSize: 40 }}>🚰</Col>
                <Col className="AlarmColumn">Buitenkraan afsluiten</Col>
                <Col className="AlarmColumn"> */}
                  {/* <span className="mdi mdi-name">F14BB</span> */}
                  {/* https://pictogrammers.github.io/@mdi/font/5.3.45/ */}
                  {/* &#xE87C; face */}
                {/* </Col> */}
              </Stack>

              <h2>Waarom moet je een buitenkraan tegen vorst beschermen?</h2>
              <p>
                Het is belangrijk om de buitenkraan in de winter goed te beschermen tegen vorst. Als water in de kraan of leidingen bevriest kan dit namelijk leiden tot lekkage. Wil je gewaarschuwd worden als het tijd is om de buitenkraan te beschermen? Als je de <Link to={'/'}>Buzzerbee app</Link> installeert, ontvang je gratis een melding als het op jouw locatie gaat vriezen.
              </p>
              <h2>Hoe bescherm je de buitenkraan tegen vorst?</h2>
              <p>
                Dit kun je doen door de kraan af te sluiten en eventueel de kraan en leidingen te isoleren.
              </p>
              <h2>Hoe sluit ik de buitenkraan af?</h2>
              <p>
                Een buitenkraan kun je afsluiten door gebruik te maken van een afsluitkraan of een afsluitventiel. Dit is een kleine kraan die je kunt openen en sluiten om de watertoevoer naar de buitenkraan te regelen. Deze vind je vaak in de kelder, garage, meterkast of in de kruipruimte onder het huis. Nadat je deze kraan hebt gesloten is het belangrijk om de buitenkraan helemaal leeg te laten lopen, zodat er geen water achterblijft in de leidingen. Als water in de leidingen bevriest kan dit namelijk leiden tot lekkage of breuk van de leidingen. Zet de buitenkraan open totdat er geen druppel meer uit komt en draai deze dan weer dicht.
              </p>
              <h2>Hoe isoleer ik de leidingen en buitenkraan?</h2>
              <p>
                Het isoleren van de leidingen en/of buitenkraan kan bijvoorbeeld door het aanbrengen van een <a href="https://www.tuinadvies.nl/tuinwinkel/product/4640/isolatie-buitenkraan" target="_blank">isolatiehuls</a>, isolatietape, een isolatiedeken of een <a href="https://www.tuinadvies.nl/tuinwinkel/product/3685/waterkraan-anti-vorstkap" target="_blank">afdekkap</a>. Ook als je deze isolatiemaatregelen toepast, blijft het belangrijk om de buitenkraan af te sluiten.
              </p>
              <h2>Wat te doen bij een bevroren buitenkraan?</h2>
              <p>
                Als de kraan of leidingen bevroren zijn geraakt en nog water bevatten, kun je het water ontdooien met behulp van een haardroger of een andere warmtebron zoals een kacheltje. Zodra het water is ontdooid kun je de buitenkraan afsluiten.
              </p>
            </Col>
          </Row>
        </Container>
      </div>

    </div>
  );

}
export default OutsideTapPage;
