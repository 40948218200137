import React from 'react';
// import {  Link } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import beeLogo from '../../media/bee-512.png';

const NavBar = () => {
  return (
    <Navbar bg="dark" variant="dark" expand="lg" className="navbar">
      <Container>
        <Navbar.Brand href="/">
          <img
            alt=""
            src={beeLogo}
            width="30"
            height="30"
            className="navbar-logo d-inline-block align-top"
          />
          BuzzerBee
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/whatsnew">What's new</Nav.Link>
            {/* <Nav.Link href="/gettheapp">Download</Nav.Link> */}
            <NavDropdown title="Toepassingen" id="basic-nav-dropdown">
              <NavDropdown.Item href="/buitenkraan-afsluiten-bij-vorst">Buitenkraan afsluiten bij vorst</NavDropdown.Item>
              {/* <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item> */}
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;