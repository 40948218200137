import React from 'react';

const GetTheAppPage = () => {
  return (
    <div>
      <h3>Get the app</h3>
      {/* <div>
        <img src="./dog.png"/>
      </div> */}
    </div>
  );
}
export default GetTheAppPage;
